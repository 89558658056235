<template>
  <div class="container-fluid-lg flex justify-center grow relative">
    <LoginForm class="mt-28" :return-to="returnTo" />
  </div>
</template>

<script lang="ts" setup>
const { t } = useI18n()

definePageMeta({
  layout: 'login',
  pageTransition: {
    name: 'login-page',
    mode: 'out-in',
  },
})

useHead({ title: `${t('guestWorld.components.auth.login.LoginForm.signIn')}` })

const returnTo = ref('/')

onBeforeMount(() => {
  const { query } = useRoute()
  if (!query) {
    return
  }
  const returnToQuery = query.returnTo as string
  // delete the returnTo query from the url
  if (returnToQuery) {
    // delete query.returnTo

    // build the query string for the returnTo and add it to the returnTo value
    // add the query params and encode the query to support more that one query param
    returnTo.value =
      returnToQuery + getQueryStringFromObject(useRoute().query, true)
  }
})
</script>

<style></style>
